import { computed, getCurrentInstance, reactive, toRefs } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { fnModalAlert } from '@/components/common/modal/alert'
import { CommonFunction } from '@/common/CommonFunction'

const INIT_PAGE = 0
const INIT_LIMIT = 6
const INIT_TOTAL_CNT = 0

export const componentState = () => {
  const { proxy } = getCurrentInstance()
  const route = useRoute()
  const router = useRouter()
  const { getters } = useStore()
  const state = reactive({
    userData: computed(() => getters['user/getData']),
    data: {},
    page: INIT_PAGE,
    limit: INIT_LIMIT,
    totalCnt: INIT_TOTAL_CNT,
    params: computed({
      get: () => {
        return {
          page: state.page + 1,
          id: route.query.id || '',
          category: route.query.category || ''
        }
      }
    }),
    list: [],
    initCnt: 0,
    isShow: false,
    isInit: false,
    pointer: computed(() => !!window.PointerEvent),
    activeTab: 2,
    touch: {}
  })
  const { switchTab, start, move, end, setTouchPos } = CommonFunction(state)
  const fnDetail = async () => {
    state.data = await proxy.$WizzpediaSvc.postWizzpediaGetWizzpediaDetail(
      route.query
    )
  }
  const fnListFunction = async () => {
    state.isShow = false
    const res = await proxy.$WizzpediaSvc.postWizzpediaGetCommentList(
      state.params
    )
    if (res.resultCode === '0000') {
      state.page++
      if (res.list.length == 6) {
        state.totalCnt += res.list.length + 1
      } else if (res.list.length == 0) {
        state.totalCnt -= 1
      }
      for (const item of res.list) {
        if (item.commentCount > 0) {
          const params = {}
          params.parentSeq = item.seq
          params.page = 1
          const reCommentRes = await proxy.$WizzpediaSvc.postGetNestedCommentList(
            params
          )
          item.commentList = reCommentRes.list
        }
      }
      state.list = state.list.concat(
        res.list.map(item => {
          item.isReItemShow = false
          return {
            ...item
          }
        })
      )
      state.isShow = true
    } else {
      state.isShow = false
    }
  }
  const getClassName = val => {
    const score = Number(val) * 10
    if (score < 1) {
      return ''
    }
    return `n${score}`
  }
  const fnMyQuestionPage = () => router.push({ name: 'cs-my-question-index' })
  const fnModalComment = async (
    item = {},
    isReComment = false,
    isReCommentModify = false
  ) => {
    const payload = {
      component: proxy.$modalAlertNames.COMMENT,
      data: {
        data: state.data,
        isReComment: isReComment,
        isModify: false
      },
      callback: async () => {
        await fnDetail()
        fnReset()
        state.initCnt++
        state.isShow = true
      }
    }
    if (!proxy.$Util.isEmpty(item.seq)) {
      // 수정일 경우
      payload.data.data.isModify = true
      if (isReComment) {
        if (isReCommentModify) {
          payload.data.form = {
            seq: item.seq,
            tag: item.tag,
            comment: `${item.tag} ${item.comment}`
          }
        } else {
          payload.data.data.isModify = false
          payload.data.form = {
            parentSeq: item.seq,
            tag: `@${item.name}`,
            comment: `@${item.name} `
          }
        }
      } else {
        payload.data.form = {
          seq: item.seq,
          comment: item.comment,
          starRating: item.starRating
        }
      }
    }
    console.log('payload {}', payload)
    await fnModalAlert(payload)
  }
  const fnDelete = async item => {
    if (!confirm('삭제하시겠습니까?')) return
    const params = {}
    // params.id = item.id
    // params.category = item.category
    params.seq = item.seq
    const res = await proxy.$WizzpediaSvc.postWizzpediaDeleteComment(params)
    if (res.resultCode === '0000') {
      fnReset()
      state.initCnt++
      state.isShow = true
    }
    alert(res.resultMsg)
  }
  const fnNestedDelete = async item => {
    if (!confirm('삭제하시겠습니까?')) return
    const params = {}
    // params.id = item.id
    // params.category = item.category
    params.seq = item.seq
    const res = await proxy.$WizzpediaSvc.postWizzpediaDeleteNestedComment(
      params
    )
    if (res.resultCode === '0000') {
      fnReset()
      state.initCnt++
      state.isShow = true
    }
    alert(res.resultMsg)
  }
  const fnReset = () => {
    state.page = INIT_PAGE
    state.limit = INIT_LIMIT
    state.totalCnt = INIT_TOTAL_CNT
    state.list = []
  }
  const fnOpen = item => {
    if (proxy.$Util.isEmpty(item.url) || !item.url.startsWith('http')) {
      alert('바로가기 정보가 없습니다.')
      return
    }
    window.open(item.url, '_blank')
  }
  const fnUpdateCommentRecommend = async item => {
    if (proxy.$Util.isEmpty(state.userData)) {
      alert('로그인이 필요합니다.')
      item.recommendMine = false
      return false
    }
    const params = {}
    params.seq = item.seq
    const res = await proxy.$WizzpediaSvc.postWizzpediaUpdateCommentRecommend(
      params
    )
    if (res.resultCode !== '0000') {
      alert(res.resultMsg)
    } else {
      if (item.recommendMine) item.recommendCount--
      else item.recommendCount++
      item.recommendMine = !item.recommendMine
    }
  }
  const init = async () => {
    await fnDetail()
    await fnListFunction()
    state.isInit = true
    state.isShow = true
  }
  init()
  return {
    ...toRefs(state),
    fnListFunction,
    getClassName,
    fnMyQuestionPage,
    fnModalComment,
    fnDelete,
    fnOpen,
    fnUpdateCommentRecommend,
    fnNestedDelete,
    switchTab,
    start,
    move,
    end,
    setTouchPos
  }
}
