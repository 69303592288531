<template>
  <main class="main" role="main">
    <div class="page">
      <div class="inner">
        <div class="page_con" v-if="!$Util.isEmpty(data)">
          <div class="lump">
            <div class="wzp_list_li">
              <div class="box txt">
                <div class="party_ttl thumb_txt">
                  <div class="party_tag">
                    <!--                    <div class="left">-->
                    <!--                      <div-->
                    <!--                        class="pt_tag logo"-->
                    <!--                        style="&#45;&#45;pt-bg-color&#45;&#45;pt-txt-color: #fff;"-->
                    <!--                        :style="{-->
                    <!--                          '&#45;&#45;pt-bg-color': $ConstCode.getCategoryByText(-->
                    <!--                            data.category-->
                    <!--                          ).color-->
                    <!--                        }"-->
                    <!--                      >-->
                    <!--                        {{ $ConstCode.getCategoryByText(data.category).name }}-->
                    <!--                      </div>-->
                    <!--                    </div>-->
                  </div>
                  <span>{{ data.titleKr }}</span>
                </div>
                <div class="wzp_tag">
                  <span>{{
                    !$Util.isEmpty(data.genre) ? data.genre : ''
                  }}</span>
                  <span>{{ !$Util.isEmpty(data.mfg) ? data.mfg : '' }}</span>
                  <span>{{
                    !$Util.isEmpty(data.grade) ? data.grade : ''
                  }}</span>
                  <span>{{
                    !$Util.isEmpty(data.duration) ? data.duration : ''
                  }}</span>
                </div>
              </div>
              <div class="box img">
                <div class="detail_thumb">
                  <div class="thumb_bg">
                    <img :src="data.thumbnail" :alt="data.titleKr" />
                  </div>
                  <div class="inner">
                    <img :src="data.thumbnail" :alt="data.titleKr" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="lump">
            <div class="wzp_info">
              <div class="wzp_info_ttl">기본 정보</div>
              <div class="wzp_info_con">
                <div>
                  {{
                    !$Util.isEmpty(data.director)
                      ? `제작진: ${data.director}`
                      : ''
                  }}
                </div>
                <div>
                  {{
                    !$Util.isEmpty(data.actor) ? `출연진: ${data.actor}` : ''
                  }}
                </div>
                <div>{{ data.synopsysKr }}</div>
              </div>
              <button
                class="btn"
                style="--pt-txt-color: #fff;"
                :style="{
                  '--pt-bg-color': $ConstCode.getCategoryByText(data.category)
                    .color
                }"
                @click="fnOpen(data)"
              >
                {{ $ConstCode.getCategoryByText(data.category).name }} 바로가기
              </button>
            </div>
          </div>
          <div class="lump">
            <!--'댓글없을 때: lump 삭제'-->
            <div class="wzp_comment" v-if="$Util.isEmpty(list)">
              <div class="stars">
                <div class="empty_wrap">
                  <div class="empty">
                    <div>등록된 댓글이 없습니다.</div>
                  </div>
                </div>
              </div>
              <button
                class="btn"
                @click="fnModalComment"
                v-if="!$Util.isEmpty(userData)"
              >
                댓글쓰기
              </button>
              <div>
                본 작품의 댓글을 다시면 위즈포인트
                <span class="color">10포인트</span>를 드립니다.
              </div>
            </div>
            <div class="wzp_comment" v-else>
              <div class="rank">
                <div class="stars" :class="getClassName(data.avgStarRating)">
                  <!--'0.5점: n05', '1.0점: n10', ~ '5.0점: n50'-->
                  <ul>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                  </ul>
                </div>
                <div class="rank_nb">
                  위즈피디아 지수 <span>{{ data.avgStarRating }}</span>
                </div>
              </div>
              <button
                class="btn"
                @click="fnModalComment"
                v-if="!$Util.isEmpty(userData)"
              >
                댓글쓰기
              </button>
              <div v-if="!$Util.isEmpty(userData)">
                작품의 평가 및 댓글을 다시면 위즈포인트
                <span class="color">10포인트</span>를 드립니다.
              </div>
            </div>
          </div>
          <div class="">
            <div
              class="comment_list"
              v-for="(item, index) in list"
              :key="index"
            >
              <ul class="comment_list_ul" :class="{ line: index > 0 }">
                <li class="comment_list_li">
                  <div class="top">
                    <div
                      class="main_star_heart"
                      v-if="item.status !== 'DELETE'"
                    >
                      <div class="rank">
                        <div
                          class="stars"
                          :class="getClassName(item.starRating)"
                        >
                          <!--'0.5점: n05', '1.0점: n10', ~ '5.0점: n50'-->
                          <ul>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                          </ul>
                        </div>
                        <div class="rank_nb">
                          <span>{{ item.starRating }}</span>
                        </div>
                      </div>
                      <div class="heart_area">
                        <div class="heart_nb">{{ item.recommendCount }}</div>
                        <label class="checkbox">
                          <input
                            type="checkbox"
                            :checked="item.recommendMine"
                          />
                          <span
                            class="label"
                            @click.prevent="fnUpdateCommentRecommend(item)"
                          >
                          </span>
                        </label>
                      </div>
                    </div>
                    <div
                      class="comment_list_ttl"
                      v-if="item.status !== 'DELETE'"
                    >
                      <div class="left">
                        <div class="name">
                          {{ item.name || item.wizzneyId }}
                        </div>
                        <div class="color" v-show="item.critic">Critic</div>
                      </div>
                    </div>
                    <div class="btm">
                      <span v-if="item.status !== 'DELETE'">{{
                        item.comment
                      }}</span>
                      <span v-else>삭제된 댓글 입니다.</span>
                    </div>
                    <div class="comment_list_ttl">
                      <div class="left">
                        <div class="date">
                          {{ $dayjs(item.wrtime).format('YYYY.MM.DD') }}
                          <span
                            class="color_error"
                            v-show="
                              $dayjs().diff($dayjs(item.wrtime), 'hours') < 24
                            "
                          >
                            N</span
                          >
                        </div>
                      </div>
                      <div class="right" v-if="item.mine">
                        <button @click="fnModalComment(item)">수정</button>
                        <button @click="fnDelete(item)">삭제</button>
                        <button
                          v-if="
                            !$Util.isEmpty(userData) && item.status !== 'DELETE'
                          "
                          @click="fnModalComment(item, true)"
                        >
                          댓글
                        </button>
                      </div>
                      <div
                        class="right"
                        v-else-if="
                          !$Util.isEmpty(userData) && item.status !== 'DELETE'
                        "
                      >
                        <button @click="fnModalComment(item, true)">
                          댓글
                        </button>
                      </div>
                    </div>
                  </div>
                </li>
                <li v-if="item.commentCount > 0">
                  <button
                    class="recomment_fold"
                    @click="item.isReItemShow = !item.isReItemShow"
                  >
                    {{
                      item.isReItemShow
                        ? '댓글접기'
                        : `댓글 ${item.commentCount}개`
                    }}
                  </button>
                </li>
                <li
                  class="comment_list_li recomment"
                  :class="{ on: item.isReItemShow }"
                  v-for="(reItem, reIndex) in item.commentList"
                  :key="`re_${index}_${reIndex}`"
                >
                  <div class="top">
                    <div class="comment_list_ttl">
                      <div class="left">
                        <div class="name">{{ reItem.name }}</div>
                        <div class="color" v-show="reItem.critic">Critic</div>
                      </div>
                    </div>
                  </div>
                  <div class="btm">
                    <span
                      ><!--붙여쓰기--><span
                        class="comment_mention"
                        v-if="!$Util.isEmpty(reItem.tag)"
                        >{{ reItem.tag }} </span
                      >{{ reItem.comment
                      }}<!--붙여쓰기--></span
                    >
                  </div>
                  <div class="comment_list_ttl">
                    <div class="left">
                      <div class="date">
                        {{ $dayjs(reItem.wrtime).format('YYYY.MM.DD') }}
                        <span
                          class="color_error"
                          v-show="
                            $dayjs().diff($dayjs(reItem.wrtime), 'hours') < 24
                          "
                        >
                          N</span
                        >
                      </div>
                    </div>
                    <div class="right" v-if="reItem.mine">
                      <button @click="fnModalComment(reItem, true, true)">
                        수정
                      </button>
                      <button @click="fnNestedDelete(reItem)">삭제</button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <observe-visible
              :key="initCnt"
              :page="page"
              :limit="limit"
              :totalCnt="totalCnt"
              @fetch="fnListFunction"
              v-show="isShow"
              v-if="isInit"
            />
          </div>
          <div class="caution">
            <div class="inner">
              <div>
                ※ wizzpedia의 영상 콘텐츠 정보는 서비스 제공사(OTT 플랫폼) 의
                정책에 따라 변경될 수 있습니다.<br />
                제공 내용이 변경되었다면, 1:1문의의 이용 신고로 제보해 주세요.
              </div>
              <button
                class="btn line"
                @click="fnMyQuestionPage"
                v-if="!$Util.isEmpty(userData)"
              >
                1:1 문의하기
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { componentState } from './index.js'

export default {
  name: 'community-wizzpedia-view-index',
  setup() {
    const state = componentState()
    return { ...state }
  }
}
</script>

<style scoped></style>
